<template>
  <BaseModal
    name="Modal-traffic"
    id="Modal-traffic"
    idModal="Modal-traffic"
    size="lg"
    title="Gestão de dados"
    @shown="fetchAll"
  >
    <div>
      <p class="desc">Adicione as informações da sua campanha.</p>
    </div>
    <div
      class="container-inputs mt-5"
      style="height: 300px !important"
      v-if="!loading"
    >
      <b-form-group label="Data dos dados" label-for="method">
        <DateRangePicker
          v-model="dateRange"
          ref="picker"
          opens="rigth"
          class="dateFilter"
          :showWeekNumbers="false"
          :locale-data="localeDate"
          :showDropdowns="true"
          :autoApply="false"
          :ranges="dateRanges"
        >
          <template
            v-if="dateRange.startDate && dateRange.endDate"
            v-slot:input="picker"
            >{{ picker.startDate | date }} -
            {{ picker.endDate | date }}
          </template>
          <!-- <template
            v-if="dateRange.startDate && dateRange.endDate"
            v-slot:input="picker"
          >
            <span style="text-align: center">
              {{ picker.startDate | date }}</span
            >
          </template> -->
          <template v-else v-slot:input> Selecione a data </template>
        </DateRangePicker>
      </b-form-group>
      <!-- Planoe e valor investido -->
      <div class="groups-inputs">
        <b-form-group label-for="method" class="form-relative">
          <div class="add-link-body">
            <div class="label-flex">
              <label for="Plano">Plano</label>
            </div>
            <router-link
              v-if="!all_plans.length && verificUserLevel"
              :to="{ name: 'PlanoLista' }"
              class="add-link link mb-0"
              >Deseja criar um plano?</router-link
            >
          </div>
          <div>
            <!-- <multiselect
              style="margin-bottom: 3px !important"
              id="plan"
              v-model="selected_plan"
              label="title"
              track-by="id"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="all_plans"
              :multiple="false"
              :taggable="false"
              :searchable="true"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
              @input="changePlannel"
            >
              <span slot="noOptions" style="font-size: 14px !important"
                >Pesquise por um plano</span
              >
              <span slot="noResult" style="font-size: 14px !important"
                >Oops! Nenhum plano encontrado.</span
              >
            </multiselect> -->
            <BaseSelect
              :selectModel="selected_plan"
              placeholder="Selecionar o plano"
              track-by="id"
              :array="all_plans"
              trackname="title"
              :multiple="false"
              :searchable="true"
              selectLabel="Clique para selecionar"
              deselectLabel="Clique para remover"
              @callback="fetchPlans($event)"
              @search="debouncePlans($event)"
              :loading="loadingPlans"
              @change="selected_plan = $event"
              :watch="true"
            >
            </BaseSelect>

            <span
              class="msgError"
              v-show="errorPlan"
              v-if="selected_plan == '' || !selected_plan"
              >Selecione um plano</span
            >
          </div>
        </b-form-group>

        <b-form-group
          label="Investimento"
          label-for="method"
          class="form-relative"
        >
          <div>
            <money
              id="amount_invest"
              v-model="invest2"
              v-bind="money"
              placeholder="Valor investido"
              class="w-100"
              :disabled="disabledInvest"
            ></money>
          </div>
        </b-form-group>
      </div>

      <!-- Canal e categoria-->
      <div class="groups-inputs">
        <b-form-group label="Canal" label-for="method" class="form-relative">
          <div>
            <multiselect
              style="margin-bottom: 3px !important"
              id="channel"
              v-model="selected_channel"
              label="title"
              track-by="id"
              :disabled="disabledChannel"
              placeholder="Selecione o canal"
              selectLabel=""
              :searchable="false"
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="channels"
              :multiple="false"
              :taggable="false"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
            >
              <span slot="noOptions" style="font-size: 14px !important"
                >Pesquise por um canal</span
              >
              <span slot="noResult" style="font-size: 14px !important"
                >Oops! Nenhum canal encontrado.</span
              >
            </multiselect>
            <span
              class="msgError"
              v-show="errorChannel"
              v-if="selected_channel == '' || !selected_channel"
              >Selecione o canal</span
            >
          </div>
        </b-form-group>

        <b-form-group
          label="Objetivo de Campanha"
          label-for="method"
          class="form-relative"
        >
          <div>
            <multiselect
              style="margin-bottom: 3px !important"
              id="channel"
              v-model="selected_category"
              label="title"
              track-by="id"
              placeholder="Selecione o objetivo"
              selectLabel=""
              deselectLabel=""
              :searchable="false"
              selectedLabel=""
              :disabled="disabledCategory"
              open-direction="bottom"
              :options="categories"
              :multiple="false"
              :taggable="false"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
            >
              <span slot="noOptions" style="font-size: 14px !important"
                >Pesquise por um canal</span
              >
              <span slot="noResult" style="font-size: 14px !important"
                >Oops! Nenhum canal encontrado.</span
              >
            </multiselect>
            <span
              class="msgError"
              v-show="errorCategory"
              v-if="selected_category == '' || !selected_category"
              >Selecione o objetivo</span
            >
          </div>
        </b-form-group>
      </div>
      <!-- Cliques -->
      <div style="margin-top: 12px" class="groups-inputs">
        <b-form-group
          label="Cliques (opcional)"
          label-for="method"
          class="form-relative"
        >
          <div>
            <input
              id="clicks"
              type="number"
              v-model="clicks"
              :disabled="disabledClick"
              placeholder="Informe a quantidade de cliques"
            />
          </div>
        </b-form-group>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4 mt-5" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton
        variant="primary"
        class="mt-5"
        :disabled="loading"
        @click="onsubmit"
      >
        Salvar item
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center loading" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>

<script>
import { Money } from "v-money";
//
import moment from "moment-timezone";
import DateRangePicker from "vue2-daterange-picker";
//
import Multiselect from "vue-multiselect";
//
import FunnelnService from "@/services/resources/CampaignService";
const serviceFunnel = FunnelnService.build();
//
import PlanService from "@/services/resources/PlanService";
const servicePlan = PlanService.build();
//
import ProductIntegrationService from "@/services/resources/ProductIntegrationService";
const serviceProductIntegration = ProductIntegrationService.build();
//
import TrafficService from "@/services/resources/TrafficService";
const serviceTraffic = TrafficService.build();
import _ from "lodash";

export default {
  components: {
    Money,
    Multiselect,
    DateRangePicker,
  },
  data() {
    return {
      loadingPlans: false,
      searchPlans: "",
      pagePlans: 1,
      stopPlans: false,
      dateRange: {
        startDate: moment().add(1, "days").format("YYYY-MM-DD"),
      },
      errorPlan: false,
      errorChannel: false,
      disabledInvest: false,
      errorCategory: false,
      disabledPlan: false,
      disabledChannel: false,
      disabledCategory: false,
      disabledClick: false,
      infoInvest: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " ",
        precision: 2,
        masked: true,
      },
      acess: "",
      invest: 0,
      invest2: 0,
      clicks: "",
      createPlanInvalid: false,
      products: [],
      simulator_data: {},
      selected_channel: "",
      channels: [
        { id: 1, title: "MetaAds" },
        { id: 2, title: "GoogleAds" },
        { id: 3, title: "Twitter" },
        { id: 4, title: "Linkedin" },
        { id: 5, title: "TikTok" },
      ],
      selected_category: "",
      categories: [
        { id: 1, title: "Captura de Lead" },
        { id: 2, title: "Remarketing" },
        { id: 3, title: "Outros" },
      ],
      all_plans: [],
      selected_plan: "",
      funnels: [],
      selected_funnel: { id: null, title: "Selecione o funil de vendas" },
      loading: false,
      localeDate: {
        direction: "ltr",
        format: "dd/mm/yyyy",
        separator: " - ",
        applyLabel: "Aplicar",
        cancelLabel: "Cancelar",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        firstDay: 0,
      },
      selectPlan: false,
    };
  },
  watch: {
    // invest2(event) {
    //   var result = event.replace("R$ ", "").replace(",", ".").replace(".", "");
    //   this.invest = result.split(".").join("");
    // },
  },

  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let nowtoday = new Date();
      nowtoday.getDay() + 1;

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let lastWeek = new Date();
      lastWeek.setDate(today.getDate() - 7);
      lastWeek.setHours(0, 0, 0, 0);

      return {
        Hoje: [today, nowtoday],
        // Ontem: [yesterday, yesterday],
        "Última semana": [lastWeek, today],
        "Este mês": [
          new Date(today.getFullYear(), today.getMonth(), 1),
          new Date(today.getFullYear(), today.getMonth(), 30),
        ],
        "Este ano": [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        "Último mês": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "Todo período": [
          new Date(2015, 0, 1),
          new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        ],
      };
    },
  },

  filters: {
    formatMoney(number) {
      if (typeof number !== "undefined" && number && number != 0) {
        number = parseFloat(number);
        const formato = {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
          useGrouping: true,
        };
        return number.toLocaleString("pt-BR", formato);
      }
      return "0,00";
    },
  },
  mounted() {
    if (this.$route.query.ModalTraffic) {
      this.$bvModal.show("Modal-traffic");
      this.selectPlan = true;
    }
  },
  methods: {
    cancel() {
      (this.errorPlan = false),
        (this.errorChannel = false),
        (this.errorCategory = false);
    },

    changePlannel() {
      if (this.selected_plan.id === null) {
        this.selected_plan = { id: null, title: "Selecione o plano" };
        return;
      }
      var investimento = this.selected_plan.metas.find(
        (item) => item.meta_key == "investimento"
      ).meta_value;
      investimento = investimento
        .replace("R$ ", "")
        .replace(",", ".")
        .replace(".", "");
      this.invest = investimento;
    },
    changeFunnel() {
      if (this.selected_funnel.id === null) {
        this.selected_funnel = {
          id: null,
          title: "Selecione o funil de vendas",
        };
        return;
      }
      var data = {
        id: "get-info",
        campaign_id: this.selected_funnel.id,
      };
      serviceFunnel
        .createId(data)
        .then((resp) => {
          this.acess = resp.views;
        })
        .catch((error) => {});
    },
    onsubmit() {
      this.simulator_data = {
        clicks: this.clicks,
        startDate: this.dateRange.startDate,
        endDate: this.dateRange.endDate,
        campaigns_ids: JSON.stringify(this.selected_plan.campaigns.map(x => x.id)),
      };

      var data = {
        plan_id: this.selected_plan.id,
        title: this.selected_plan.title,
        acess: this.acess,
        investing: this.invest2,
        origin: this.selected_channel.title,
        category:
          this.selected_category.title == "Outros"
            ? "others"
            : this.selected_category.title == "Remarketing"
            ? "remarketing"
            : this.selected_category.title == "Captura de Lead"
            ? "capture"
            : this.selected_category.title == "Selecione o objetivo"
            ? ""
            : "",
        metas: this.simulator_data,
      };

      if (this.selected_plan == "" || !this.selected_plan) {
        this.errorPlan = true;
        this.loading = false;
      }

      if (this.selected_channel == "" || !this.selected_channel) {
        this.errorChannel = true;
        this.loading = false;
      }

      if (this.selected_category == "" || !this.errorCategory) {
        this.errorCategory = true;
        this.loading = false;
      }

      this.loading = true;
      serviceTraffic
        .create(data)
        .then((resp) => {
          this.$bvToast.toast("Tráfego criado com sucesso", {
            title: "Tráfego",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.selected_category = "";
          this.selected_channel = "";
          this.selected_plan = "";
          this.invest2 = "";
          this.clicks = "";

          //validaçoes
          (this.errorPlan = false),
            (this.errorChannel = false),
            (this.errorCategory = false),
            //

            this.$bvModal.hide("Modal-traffic");
          this.$emit("create-traffic");
        })
        .catch((error) => {
          this.$bvToast.toast("Erro ao criar tráfego", {
            title: "Tráfego",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    debouncePlans: _.debounce(function (query) {
      if (query === "") {
        this.loadingPlans = false;
      } else {
        this.searchPlans = query;
        this.fetchPlans(this.pagePlans, query);
      }
    }, 500),
    fetchPlans(page = 1, query = "") {
      if (this.stopPlans || this.loadingPlans) {
        return;
      }

      this.loadingPlans = true;

      var data = {
        // id: `list?page=${page}&order_by=title&order=ASC&search=${query}`,
        page: page,
        order_by: "title",
        status: "active",
        order: "ASC",
        search: query,
      };

      servicePlan
        .search(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page && query == "") {
            this.stopPlans = true;
          }

          this.all_plans = this.all_plans.concat(resp.data);
          this.all_plans = this.all_plans.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
          if (this.selectPlan && this.$route.query.ModalTraffic) {
            setTimeout(() => {
              this.selected_plan = this.all_plans.find(
                (plan) => plan.id == this.$route.query.ModalTraffic
                );
            }, 300);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingPlans = false;
        });
    },

    fetchProducts() {
      serviceProductIntegration.search().then((resp) => {
        this.products = resp.products;
      });
    },
    fetchFunnels() {
      let data = {
        status: "active",
        per_page: 9999999999,
      };
      serviceFunnel
        .search(data)
        .then((response) => {
          this.funnels = response.data;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    async fetchAll() {
      this.selected_category = "";
      this.invest2 = "";
      this.clicks = "";
      this.selected_channel = "";
      this.selected_plan = "";

      this.loading = true;
      await this.fetchFunnels();
      await this.fetchProducts();
      await this.fetchPlans();
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
input,
#input {
  width: 100%;
}
.multiselect__input,
.multiselect__single {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  color: #000;
}

.container-inputs {
  display: grid;
  gap: 20px;
}
.desc {
  color: #81858e;
  font-size: 14px;
  margin-top: -40px !important;
}
.loading {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.groups-inputs {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-group {
      width: 100%;
    }
  }
}
.msgError {
  font-size: 12px;
  font-weight: 500;
  margin-left: 3px;

  color: #dc3545 !important;
}
</style>
